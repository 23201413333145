.PageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.CenterHV {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageContent {
  flex-grow: 1;
  background-color: #fff;
}

.DragAndDrop {
  cursor: pointer;
  background-color: #eff6ff;
  border-radius: 4px;
  border-style: dashed;
  border-color: #d6dfe9;
  text-align: center;
  margin-top: 24px;
  padding: 16px;
}

.Header {
  background-color: #242b50;
  padding: 24px 0px 24px 76px;
}

.Footer {
  background-color: #242b50;
  padding: 24px 0px 24px 76px;
}

.CardContent {
  background-color: white;
  padding: 32px;
  width: 500px;
  box-shadow: 0px 0px 15px #cccccc;
  border-radius: 20px;
  min-height: 500px;
}

.CardContentWithList {
  background-color: white;
  padding: 32px;
  width: 1200px;
  box-shadow: 0px 0px 15px #cccccc;
  border-radius: 20px;
  min-height: 500px;
}

.PrimaryButton {
  background-color: rgb(36, 43, 80);
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.PrimaryButton > span {
  cursor: pointer !important;
}

.PrimaryButtonDisabled {
  color: #6a6a6a;
  background-color: #e6e6e6;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
}

.LabelForm {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.FormLogin {
  padding: 80px 32px;
}

.table-striped > tbody > tr {
  vertical-align: middle;
  font-size: 14px;
}

.RemoveDefault {
  text-decoration: none;
}

.RemoveDefault:hover {
  color: #fff;
}

.set-color-white{
  color: #fff !important;
}

@media (max-width: 800px) {
  .hstack {
    display: block;
  }

  .Footer{
    padding: 24px 0px 24px 0;
    text-align: center;
  }

  .Header{
    padding: 24px 0px 24px 0;
    text-align: center;
  }

  .Header > .hstack > .ms-auto {
    margin-top: 30px;
  }

  .ms-auto {
    padding-right: 0px !important;
  }

  .PrimaryButtonDisabled, .PrimaryButton{
    margin-top: 20px;
  }

}

@media (max-width: 500px) {
  .PageContent {
    padding: 20px 20px;
  }
}

@media (max-width: 1200px) {
  .PageContent {
    padding: 20px 20px;
  }
}